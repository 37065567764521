import { SvgIcon, SvgIconProps } from '@material-ui/core';

/** ********************************************************************
    INSTRUCTIONS FOR ADDING NEW ICONS
    MAKE SURE THAT NAME IS GIVING DESCRIPTION OF ICON
    'Icon' SHOULD BE INCLUDED IN NAME
    Use 'color' attribute if you want to use color provided by theme 
    Use 'fontSize' attribute to handle size of icon
    Use 'htmlColor' attribute to add custom color to icon
    Use 'inheritViewBox' attribute if you want to override defualt viewBox
    Use 'viewBox' attribute if you want to add custom viewBox
    refrences: https://mui.com/material-ui/api/svg-icon/#main-content
********************************************************************** */

export const SpinnerIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 38 38"
    stroke="#444"
    className="ais-SearchBox-loadingIcon"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </SvgIcon>
);
