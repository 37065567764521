import { Box, Divider } from '@mui/material';
import { SText } from '@setvi/shared/components';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface RevenueTargetProps {
  title: string;
  currentValue: number;
  targetValue: number;
  pendingValue: number;
  completed: boolean;
}

export const RevenueTarget = ({
  title,
  currentValue,
  targetValue,
  pendingValue,
  completed
}: RevenueTargetProps) => (
  <>
    <Divider />
    <Box
      py="12px"
      px={4}
      sx={{
        backgroundColor: '#FAFAFB'
      }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <SText size="xs" title={title} fontColor="#696974" />
          <Box display="flex" gap="5px">
            <SText
              size="lg"
              title={currentValue.toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
              fontColor="#000"
              weight="bold"
            />
            <SText size="lg" title="/" fontColor="#000" weight="bold" />
            <SText
              size="lg"
              title={targetValue.toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
              fontColor="#696974"
              weight="bold"
            />
          </Box>
        </Box>
        {completed ? (
          <Box display="flex" gap="10px">
            <CheckCircleRoundedIcon htmlColor="#23A566" />
            <SText size="lg" title="Completed" weight="bold" />
          </Box>
        ) : (
          <Box>
            <SText size="xs" title="Pending Target" fontColor="#696974" />
            <SText
              title={pendingValue.toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
              weight="bold"
              size="lg"
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Box>
  </>
);
