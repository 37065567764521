import { useEffect } from 'react';
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { SAvatar, SText } from '@setvi/shared/components';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DraftsIcon from '@mui/icons-material/Drafts';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import LaptopIcon from '@mui/icons-material/Laptop';
import { VetsuiteOverview } from '@setvi/shared/services';
import { getInitials } from '@setvi/shared/utils';
import { useDigitalShowroomContext } from '../../../../../../providers/app-provider/context';
import { useVetsuiteDigitalShowroomContext } from '../../../../providers/context';
import { useVetSuiteDigitalShowroomAnalytics } from '../../../../hooks';
import { CompanyContact } from './company-contact';
import { Section } from '../../../../../../components/section';

interface ContactUsProps {
  overview: VetsuiteOverview;
}

export const ContactUs = ({ overview }: ContactUsProps) => {
  const { loggedInUserEmail } = useDigitalShowroomContext();
  const { customerId, setvLoggedInUserEmail } =
    useVetsuiteDigitalShowroomContext();
  const { captureAnalytics } = useVetSuiteDigitalShowroomAnalytics();
  const t = useTheme();
  const matches = useMediaQuery(t.breakpoints.down('md'));

  useEffect(() => {
    const el = document.getElementById('ipad-scroll-container');
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (loggedInUserEmail || setvLoggedInUserEmail)
      captureAnalytics({
        actionType: 'ContactUsTab',
        userEmail: loggedInUserEmail || setvLoggedInUserEmail,
        timestamp: new Date(),
        covetrusKey: customerId,
        type: window.location.origin.includes('vetsuite')
          ? 'external'
          : 'internal'
      });
  }, [captureAnalytics, customerId, loggedInUserEmail, setvLoggedInUserEmail]);

  return (
    <Section>
      <SText noLineClamp title="Covetrus Support" size="3xl" weight="bold" />
      <Box sx={theme => ({ color: theme.palette.grey[900] })} mt={2}>
        <SText
          noLineClamp
          title="Your dedicated team is here to help you achieve success"
        />
      </Box>
      <Box
        p={2}
        mt={3}
        display="flex"
        sx={theme => ({
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '17px',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
          }
        })}>
        <Box minWidth={240} display="flex" alignItems="center" py={2} px={1}>
          <SAvatar
            style={{ backgroundColor: '#1E96FC' }}
            value={getInitials({ fullName: overview.managerName })}
          />
          <Box ml={2}>
            <SText title={overview?.managerName} weight="bold" />
            <SText title="Veterinary Relationship Manager" />
          </Box>
        </Box>
        {/* <Box display="inline-flex"> */}
        <Divider
          flexItem={!matches}
          orientation={!matches ? 'vertical' : 'horizontal'}
        />
        {/* </Box> */}
        {/* <Box ml={3} flex={1} display="flex" alignItems="center">
        <LocalPhoneIcon color="primary" />
        <Box ml={2}>
          <a href="tel:2095550104">(209) 555-0104</a>
        </Box>
      </Box> */}
        <Box
          display="flex"
          alignItems="center"
          p={3}
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              padding: '8px'
            }
          })}>
          <DraftsIcon color="primary" />
          <Box ml={2}>
            <a href={`mailto:${overview?.managerEmail}`}>
              {overview?.managerEmail}
            </a>
          </Box>
        </Box>
      </Box>
      <Box pt={5}>
        <SText title="Company Contact" size="3xl" weight="bold" />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={matches ? 12 : 2} display="flex" alignItems="center">
          <SText title="Address" weight="bold" />
        </Grid>
        <Grid item xs={matches ? 12 : 10}>
          <CompanyContact>
            <Box display="flex" alignItems="center">
              <LocationOnIcon color="primary" />
            </Box>
            <Box ml={2}>
              <SText
                noLineClamp
                title="12 Mountfort Street, Portland, ME 04101"
              />
            </Box>
          </CompanyContact>
        </Grid>
        <Grid item xs={matches ? 12 : 2} display="flex" alignItems="center">
          <SText title="Website" weight="bold" />
        </Grid>
        <Grid item xs={matches ? 12 : 10}>
          <CompanyContact>
            <Box display="flex" alignItems="center">
              <LanguageIcon color="primary" />
            </Box>
            <Box ml={2}>
              <a
                href="https://www.covetrus.com"
                target="_blank"
                rel="noreferrer">
                www.covetrus.com
              </a>
            </Box>
          </CompanyContact>
        </Grid>
        <Grid item xs={matches ? 12 : 2} display="flex" alignItems="center">
          <SText title="Shop Website" weight="bold" />
        </Grid>
        <Grid item xs={matches ? 12 : 10}>
          <CompanyContact>
            <Box display="flex" alignItems="center">
              <LaptopIcon color="primary" />
            </Box>
            <Box ml={2}>
              <a
                href="https://www.mycovetrus.com"
                target="_blank"
                rel="noreferrer">
                www.mycovetrus.com
              </a>
            </Box>
          </CompanyContact>
        </Grid>
        <Grid item xs={matches ? 12 : 2} display="flex" alignItems="center">
          <SText title="Order placement" weight="bold" />
        </Grid>
        <Grid item xs={matches ? 12 : 10}>
          <CompanyContact>
            <Box display="flex" alignItems="center">
              <LocalPhoneIcon color="primary" />
            </Box>
            <Box ml={2}>
              <a href="tel:888-280-2221">(888) 280-2221</a>
            </Box>
          </CompanyContact>
        </Grid>
        <Grid item xs={matches ? 12 : 2} display="flex" alignItems="center">
          <SText title="Support" weight="bold" />
        </Grid>
        <Grid item xs={matches ? 12 : 10}>
          <CompanyContact>
            <Box display="flex" alignItems="center">
              <LocalPhoneIcon color="primary" />
            </Box>
            <Box ml={2}>
              <a href="tel:888-280-2221">(888) 280-2221</a>
            </Box>
          </CompanyContact>
        </Grid>
      </Grid>
    </Section>
  );
};
